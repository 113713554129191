import AlertInfo from '~/components/AlertInfo'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { useAlert } from '~/hooks/useAlert'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { Footer } from '~/pages/products/list/styles'

import { useMediaReviewTable } from './hooks/useMediaReviewTable'

const MediaReviewTable = () => {
  const { t } = useAPPTranslation()

  const {
    data,
    error,
    isLoading,
    isFetching,
    page,
    perPage,
    total,
    handlePagination,
    handleItemsPerPageChange,
    schema
  } = useMediaReviewTable()

  const [AlertOnError] = useAlert(error)

  return (
    <>
      <Loading status={!data && isLoading}>
        {t('common:actions.loadingData')}...
      </Loading>

      {!!total && (
        <>
          <hr />

          <AlertOnError />

          <Loading status={isFetching} onlyLine />

          {!!data && (
            <Table
              schema={schema}
              data={data}
              total={total}
              sideBorder
              smallerRowPadding
            />
          )}

          {total === 0 && data && (
            <AlertInfo
              template="warning"
              text={t('common:table.emptyFiltered')}
            />
          )}

          {total > perPage && (
            <Footer>
              <ListPagination
                total={total}
                label={total > 1 ? t('rm:ads') : t('rm:ad')}
                currentPage={page}
                itemsPerPage={perPage}
                menuPlacement="top"
                onClickPagination={handlePagination}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </Footer>
          )}
          {/* </Panel> */}

          <footer className="d-flex flex-direction-column align-items-center">
            <ListPagination
              total={total}
              label={total > 1 ? t('rm:ads') : t('rm:ad')}
              currentPage={page}
              itemsPerPage={perPage}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </footer>
        </>
      )}
    </>
  )
}

export default MediaReviewTable
