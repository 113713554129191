import { t } from 'i18next'

import AdInfo from './elements/AdInfo'

export const schema: SchemaMediaRejectedTable = () => ({
  /**
   * Dados do anúncio
   */
  title: {
    title: t('rm:adReview.list.title.main'),
    render: AdInfo,
    showTotal: true
  },
  note: {
    title: t('rm:Note'),
    render: rowData => rowData?.settings?.note,
    showTotal: true
  }
})
