import { useMemo, useState } from 'react'
import { FiCheck, FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

import AlertNew from '~/components/AlertNew'
import { Button } from '~/components/Buttons'
import Panel from '~/components/Panel'
import Status from '~/components/Status'
import { getRetailMediaAccountType } from '~/helpers'
import useModal from '~/hooks/useModal'
import { useAppSelector } from '~/store/hooks'

import MediaReviewTable from '../_tables/MediaReviewTable'
import {
  handleStatusCampaign,
  handleStatusLocal
} from '../../store/grocery/campaign/actions'
import { Alerts } from './_components/Alerts'
import { ContentModal, ModalConfirmations } from './_components/Modal'
import { RejectMessage } from './_components/RejectMessage'
import { statusType } from './statusType'

import * as S from './styles'

interface Props {
  status: CampaignStatus
  message: string
}

type CallbackData = {
  status: string
  status_history: {
    status: string
    note: string
  }[]
}

export const ReviewBar = ({ status, message }: Props) => {
  const isPublisher = getRetailMediaAccountType() === 'publisher'
  const [contentModal, setContentModal] = useState<ContentModal | null>(null)

  const [Modal, showConfirmationModal, toggleConfirmationModal, setIsShowing] =
    useModal()

  const dispatch = useDispatch()

  const {
    campaign: {
      item: { data }
    },
    grocery: {
      campaign: {
        patch: { loading: loadingPatch }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  // Consts

  const typeHasReview = [
    'banner',
    'sponsored_brand',
    'digital_signage'
  ].includes(data?.adType)

  const showReviewActions = useMemo(
    () => isPublisher && typeHasReview && status === 'pending_review',
    [isPublisher, status, typeHasReview]
  )

  const typeHasPublish = ['digital_signage'].includes(data?.adType)

  const showAwaitingAction = useMemo(
    () => isPublisher && typeHasPublish && status === 'approved',
    [isPublisher, status, typeHasPublish]
  )

  const showPublishAction = useMemo(
    () => isPublisher && typeHasPublish && status === 'awaiting_publication',
    [isPublisher, status, typeHasPublish]
  )

  const isRejected = useMemo(() => status === 'rejected', [status])

  // Handles

  const handleReject = () => {
    setContentModal({
      titleModal: t('rm:reviewCampaign.reject.title.modal'),
      labelMessage: t('rm:reviewCampaign.reject.label.message'),
      subtitletLabelMessage: t(
        'rm:reviewCampaign.reject.subtitle.label.message'
      ),
      textButton: t('rm:button.Reject'),
      templateButton: 'danger',
      status: 'rejected',
      action: 'review',
      noteIsRequired: true,
      hasCancelButton: true
    })

    toggleConfirmationModal()
  }

  const handleApprove = () => {
    setContentModal({
      titleModal: t('rm:reviewCampaign.approve.title.modal'),
      titleAlertMessage: t('rm:reviewCampaign.approve.title.alert.message'),
      alertMessage: t('rm:reviewCampaign.approve.alert.message'),
      textButton: t('rm:button.Approve'),
      templateButton: 'success',
      status: 'approved',
      action: 'review',
      hasCancelButton: true
    })

    toggleConfirmationModal()
  }

  const handleSubmit = (dataForm: {
    status: CampaignStatus
    action: CampaignActions
    note: string
  }) => {
    const callbackData = {
      status: dataForm?.status,
      status_history: [
        {
          status: dataForm?.status,
          note: dataForm?.note
        }
      ]
    }

    const callbackDispatch = (data: CallbackData) => {
      dispatch(handleStatusLocal(data))

      setIsShowing(false)
    }

    dispatch(
      handleStatusCampaign({
        id: data?.id,
        body: { ...dataForm },
        onSuccess: callbackDispatch(callbackData)
      })
    )
  }

  return (
    <Panel className="border-warning">
      <S.WrapperStatus>
        <S.TitleStrong>{t('rm:CampaignReview')}</S.TitleStrong>
      </S.WrapperStatus>

      {!isPublisher && <Alerts status={status} />}

      {isRejected && <RejectMessage textMessage={message} />}

      <Alerts status={status} />

      <S.WrapperInteractions>
        <Button
          template="danger"
          onClick={handleReject}
          loading={loadingPatch}
          text={t('rm:button.RejectCampaign')}
          iconLeft={<FiX />}
        />

        <Button
          template="success"
          onClick={handleApprove}
          loading={loadingPatch}
          text={t('rm:button.ApproveCampaign')}
          iconLeft={<FiCheck />}
        />
      </S.WrapperInteractions>

      {/*
      {showAwaitingAction && (
        <>
          <section className="mt-3">
            <AlertNew color="warning">
              {t('rm:reviewCampaign.show.awaiting.action.alert')}
            </AlertNew>
          </section>
        </>
      )}

      {showPublishAction && (
        <>
          <section className="mt-3">
            <AlertNew color="warning">
              {t('rm:reviewCampaign.show.publish.action.alert')}
            </AlertNew>
          </section>
        </>
      )} */}

      <MediaReviewTable />

      <ModalConfirmations
        Modal={Modal}
        setIsShowing={setIsShowing}
        showConfirmationModal={showConfirmationModal}
        handleSubmit={handleSubmit}
        toggleConfirmationModal={toggleConfirmationModal}
        contentModal={contentModal}
      />
    </Panel>
  )
}
