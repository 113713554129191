import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import { isEqual } from 'lodash'

import handleQueryParams from '~/helpers/handleQueryParams'
import { formatAds } from '~/modules/retailMedia/dtos/common/ads'
import { listAdsResultsV2Service } from '~/modules/retailMedia/services/ad/result/v2'

import { schema } from '../schemas'

const MediaReviewTableContext = createContext<MediaReviewTableContextData>(
  {} as MediaReviewTableContextData
)

/**
 * Chaves usadas nas query params que alteram a
 * quantidade resultados e impactam na paginação
 */
const dynamicKeys: (keyof QueryParamsAds)[] = ['campaign_id', 'quantity']

const MediaReviewTableProvider = ({
  children
}: MediaReviewTableProviderProps) => {
  const [queryParams, setQueryParams] = useState({} as QueryParamsAds)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(100)

  const { id: campaignId } = useParams()

  /**
   * Handle request
   */

  const params = useMemo(() => {
    const data = {
      campaign_id: campaignId,
      page,
      quantity: perPage,
      ad_type: 'banner',
      ad_status: 'pending_review',
      // Ainda não temos suporte para 2 queries no <Filters />
      account_info: false
    } as QueryParamsAds

    return data
  }, [campaignId, page, perPage])

  useLayoutEffect(() => {
    if (isEqual(params, queryParams)) {
      return
    }

    const { shouldResetFixedKeys } = handleQueryParams({
      params,
      currentParams: queryParams,
      dynamicKeys
    })

    if (shouldResetFixedKeys) {
      params.page = 1
      setPage(1)
    }
    setQueryParams(params)
  }, [params, queryParams])

  const queryKey = ['get-ads', 'pending_review', campaignId]

  const { data, isLoading, isFetching, error } = useQuery({
    enabled: !!queryParams.campaign_id,
    queryKey,
    refetchInterval: 60000,
    queryFn: async () => {
      const response = await listAdsResultsV2Service({ params })

      return response?.data
        ? { ...response.data, data: formatAds({ ads: response.data.data }) }
        : null
    }
  })

  /**
   * Handle list
   */

  const handlePagination = useCallback((page: number) => {
    setPage(page)
  }, [])

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      setPerPage(value)
    },
    []
  )

  return (
    <MediaReviewTableContext.Provider
      value={{
        data: data?.data,
        error,
        isLoading,
        isFetching,
        page,
        perPage,
        total: data?.total || 0,
        schema: schema(),
        handlePagination,
        handleItemsPerPageChange
      }}
    >
      {children}
    </MediaReviewTableContext.Provider>
  )
}

function useMediaReviewTable(): MediaReviewTableContextData {
  const context = useContext(MediaReviewTableContext)

  if (!context) {
    throw new Error(
      'useMediaReviewTable must be used within an MediaReviewTableProvider'
    )
  }

  return context
}

export {
  MediaReviewTableContext,
  MediaReviewTableProvider,
  useMediaReviewTable
}
