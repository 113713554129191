import { useState } from 'react'
import { FiCheck, FiX } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Button } from '~/components/Buttons'
import useModal from '~/hooks/useModal'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { ModalConfirmations } from '~/modules/retailMedia/components/StatusBar/_components/Modal'
import {
  approveAdService,
  rejectAdService
} from '~/modules/retailMedia/services/ad/action/review'

const ReviewActions: React.FC<{ adId: string }> = ({ adId }) => {
  const { t, tToasts } = useAPPTranslation()

  const [contentModal, setContentModal] = useState<ContentModal | null>(null)
  const [Modal, showConfirmationModal, toggleConfirmationModal, setIsShowing] =
    useModal()

  const queryClient = useQueryClient()

  /**
   * Approve
   */

  const approveBanner = useMutation({
    mutationFn: approveAdService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-ads']
      })

      tToasts({
        prefix: 'rm:ad.messages.approve.success',
        toastFunction: toastr.success
      })
    },
    onError: () => {
      tToasts({
        prefix: 'rm:categories.messages.addCategory.error',
        toastFunction: toastr.error
      })
    }
  })

  const handleApprove = () => approveBanner.mutate({ adId })

  /**
   * Reject
   */
  const rejectBanner = useMutation({
    mutationFn: rejectAdService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-ads']
      })

      tToasts({
        prefix: 'rm:ad.messages.reject.success',
        toastFunction: toastr.success
      })

      toggleConfirmationModal()
    },
    onError: () => {
      tToasts({
        prefix: 'rm:ad.messages.reject.error',
        toastFunction: toastr.error
      })
    }
  })

  const handleReject = () => {
    setContentModal({
      titleModal: t('rm:reviewAd.reject.title.modal'),
      labelMessage: t('rm:reviewAd.reject.label.message'),
      subtitletLabelMessage: t('rm:reviewAd.reject.subtitle.label.message'),
      textButton: t('rm:button.Reject'),
      templateButton: 'danger',
      status: 'rejected',
      action: 'review',
      noteIsRequired: true,
      hasCancelButton: true
    })

    toggleConfirmationModal()
  }

  const handleSubmitReject = async ({ note }: { note: string }) => {
    rejectBanner.mutate({ adId, body: { note } })
  }

  return (
    <>
      <div className="d-flex gap-2 mt-2">
        <Button
          template="danger"
          size="small"
          customWidth="auto"
          onClick={handleReject}
          loading={rejectBanner.isPending}
          text={t('rm:button.Reject')}
          iconLeft={<FiX />}
        />

        <Button
          template="success"
          size="small"
          customWidth="auto"
          onClick={handleApprove}
          loading={approveBanner.isPending}
          text={t('rm:button.Approve')}
          iconLeft={<FiCheck />}
        />
      </div>

      <ModalConfirmations
        Modal={Modal}
        setIsShowing={setIsShowing}
        showConfirmationModal={showConfirmationModal}
        handleSubmit={handleSubmitReject}
        toggleConfirmationModal={toggleConfirmationModal}
        contentModal={contentModal}
      />
    </>
  )
}

export default ReviewActions
