import { useCallback, useEffect, useMemo, useState } from 'react'
import { FiAlertCircle, FiHelpCircle } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'

import AlertInfo from '~/components/AlertInfo'
import BadgeIsValid from '~/components/BadgeIsValid'
import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import ProgressBar from '~/components/ProgressBar'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import { FormGroup, InputMoney } from '~/components/unform'
import { formatMoney, getAccountType, unformatMoney } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import i18n from '~/i18n'
import { useAppSelector } from '~/store/hooks'

import { getMinCosts } from '../../store/industry/budget/actions'
import { patch } from '../../store/industry/campaign/actions'
import BudgetTypeScope from '../CampaignForm/elements/BudgetTypeScope'
import FormEditDailyCost from '../DailyCost/FormEditDailyCost'
import EditButton from '../EditButton'

import * as S from './styles'

interface Props {
  data: CampaignView
  loading: boolean
}

const Budget = ({ data, loading }: Props) => {
  const { lengthCentsCurrency } = useAppInfo()

  const [isEditMode, setIsEditMode] = useState(false)

  const dispatch = useDispatch()

  const isGrocery = getAccountType() === 'grocery'

  const canEdit = useMemo(() => data?.canEditCampaign, [data?.canEditCampaign])

  const {
    budget: {
      minCosts: { data: minCostsData, loading: minCostsLoading, error }
    },
    campaign: {
      patch: { loading: loadingSave }
    }
  } = useAppSelector(state => state.retailMedia.industry)

  const productsId = useMemo(
    () => data?.ads?.data?.map(item => ({ id: item.product_id })),
    [data]
  )

  useEffect(() => {
    if (data?.publisher) {
      dispatch(
        getMinCosts({
          body: { publisher_id: data.publisher.id, products: productsId }
        })
      )
    }
  }, [data, dispatch, productsId])

  const adsBelowMinCostLength = useMemo(() => {
    const ads = data?.ads?.data
    if (ads) {
      const adsWithoutCPCWithMinCost = ads?.map(item => {
        const minCost = minCostsData?.ads?.find(
          minCostItem => minCostItem.id === item.product_id
        )

        return { ...item, minCpc: minCost?.min_cpc }
      })

      return adsWithoutCPCWithMinCost.filter(item => {
        const minCPC = item?.minCpc || minCostsData?.global?.min_cpc
        const currentCPC = item?.settings?.cpc || data?.settings?.cpc

        return Number(currentCPC) < Number(minCPC)
      }).length
    }
    return 0
  }, [data, minCostsData])

  const costIsValid = useMemo(() => {
    return (
      adsBelowMinCostLength === 0 ||
      (data?.settings?.cpc || 1) >= (minCostsData?.global?.min_cpc || 0)
    )
  }, [
    adsBelowMinCostLength,
    data?.settings?.cpc,
    minCostsData?.global?.min_cpc
  ])

  const adsBelowMinCostCpmLength = useMemo(() => {
    const ads = data?.ads?.data
    if (ads) {
      const adsWithoutCPCWithMinCost = ads?.map(item => {
        const minCost = minCostsData?.ads?.find(
          minCostItem => minCostItem.id === item.product_id
        )
        return { ...item, minCpm: minCost?.min_cpm }
      })

      adsWithoutCPCWithMinCost.filter(
        item =>
          Number(item?.settings?.cpm) < Number(minCostsData?.global?.min_cpm)
      )

      return adsWithoutCPCWithMinCost.filter(item => {
        const minCPM = item?.minCpm || minCostsData?.global?.min_cpm
        const currentCPM = item?.settings?.cpm || data?.settings?.cpm

        return Number(currentCPM) < Number(minCPM)
      }).length
    }
    return 0
  }, [data, minCostsData])

  const costCpmIsValid = useMemo(() => {
    return (
      adsBelowMinCostCpmLength === 0 ||
      (data?.settings?.cpm || 1) >= (minCostsData?.global?.min_cpm || 0)
    )
  }, [
    adsBelowMinCostCpmLength,
    data?.settings?.cpm,
    minCostsData?.global?.min_cpm
  ])

  const handleSuccess = useCallback(() => {
    setIsEditMode(false)
  }, [])

  /**
   * handleSubmit
   */
  type FormData = {
    budget: {
      daily: { numAsString: string }
      cpc: { numAsString: string }
      cpm: { numAsString: string }
    }
  } & BudgetTypeScopeResponse

  const handleSubmit = useCallback(
    (formData: FormData) => {
      const parseCurrencyValue = (value: string | undefined) => {
        if (!value) return null
        const numberValue = Number(value)
        return lengthCentsCurrency ? numberValue / 100 : numberValue
      }

      const valueDailyBudget = formData.budget?.daily?.numAsString
      const valueCPC = formData.budget?.cpc?.numAsString
      const valueCPM = formData.budget?.cpm?.numAsString

      const settings = {
        budget_type: formData?.settings?.budget_type || 'fast_as_possible',
        daily_budget: parseCurrencyValue(valueDailyBudget),
        cpc: parseCurrencyValue(valueCPC),
        cpm: parseCurrencyValue(valueCPM)
      }

      dispatch(
        patch({
          body: { settings },
          id: data?.id,
          successFeedbackMsg: i18n.t('rm:budget.updated.success.message'),
          onSuccess: handleSuccess,
          values: {
            patchKey: 'budget'
          }
        })
      )
    },
    [data?.id, dispatch, handleSuccess, lengthCentsCurrency]
  )

  const initialData = useMemo(
    () => ({
      budget: {
        daily: data?.settings?.daily_budget,
        cpc: data?.settings?.cpc,
        cpm: data?.settings?.cpm
      },
      settings: {
        budget_type: data?.settings?.budget_type
      }
    }),
    [data]
  )

  return (
    <Panel
      title={i18n.t('rm:budget')}
      isLoading={loading}
      iconLeft={
        <>
          {!costCpmIsValid &&
            !!adsBelowMinCostCpmLength &&
            data.adType === 'banner' && (
              <BadgeIsValid isValid={!!costCpmIsValid} />
            )}

          {!costIsValid &&
            !!adsBelowMinCostLength &&
            data.adType === 'product' && (
              <BadgeIsValid isValid={!!costIsValid} />
            )}
        </>
      }
      template={isEditMode ? 'borderWarning' : 'default'}
      headerSideContent={
        !isGrocery &&
        canEdit && (
          <EditButton
            onClick={() => setIsEditMode((e: boolean) => !e)}
            isActive={isEditMode}
          />
        )
      }
    >
      {!!data?.settings?.daily_cost && (
        <S.Wrapper
          className={
            isEditMode ? 'flex-grow-1 border-0 border-top px-0 pt-3' : ''
          }
        >
          {!isEditMode && (
            <S.Text>
              {i18n.t('rm:settings.daily_cost.title')}
              <Tooltip
                icon={<FiHelpCircle />}
                text={i18n.t('rm:settings.daily_cost.view.description')}
              />
            </S.Text>
          )}

          {!isEditMode && <p>{formatMoney(data?.settings?.daily_cost)}</p>}

          {isEditMode && <FormEditDailyCost data={data} />}
        </S.Wrapper>
      )}

      <Form
        onSubmit={handleSubmit}
        initialData={initialData}
        className="form-default"
      >
        {!costIsValid &&
          !!adsBelowMinCostLength &&
          data.adType === 'product' && (
            <AlertInfo
              text={i18n.t('rm:adsBelowMinCostCPC.alert', {
                quantity: adsBelowMinCostLength
              })}
            />
          )}

        {!costCpmIsValid &&
          !!adsBelowMinCostCpmLength &&
          data.adType === 'banner' && (
            <AlertInfo
              text={i18n.t('rm:adsBelowMinCostCPM.alert', {
                quantity: adsBelowMinCostCpmLength
              })}
            />
          )}

        <S.RowWrapper className="hasBorder">
          <S.GroupWrapper>
            {!!data?.settings?.daily_budget && (
              <S.Wrapper className="d-flex align-items-start">
                <div className="mainColumn">
                  <S.Text>{i18n.t('rm:dailyBudget')}</S.Text>
                  {!isEditMode && (
                    <p>{formatMoney(data.settings.daily_budget)}</p>
                  )}

                  {isEditMode && (
                    <InputMoney
                      id="budget.daily"
                      name="budget.daily"
                      required
                      loading={minCostsLoading}
                    />
                  )}
                </div>

                <S.ContainerProgress>
                  <header>
                    <div className="column">
                      <h6 className="title">{i18n.t('rm:used')}</h6>

                      <span className="value">{data.budget.spent}</span>
                    </div>
                    <div className="column">
                      <h6 className="title">{i18n.t('rm:available')}</h6>
                      <span className="value">
                        {formatMoney(
                          data.budget.raw.dailyBudget -
                            data.budget.raw.dailyConsumed
                        )}
                      </span>
                    </div>
                  </header>
                  <ProgressBar
                    goal={data.budget.raw.dailyBudget}
                    value={data.budget.raw.dailyConsumed}
                  />
                </S.ContainerProgress>
              </S.Wrapper>
            )}

            {!!data?.settings?.cpc && (
              <S.Wrapper>
                <S.Text>
                  {i18n.t('rm:cpc.title')}
                  <Tooltip
                    icon={<FiHelpCircle />}
                    text={i18n.t('rm:metrics.cpc.tooltip')}
                  />
                </S.Text>

                {!isEditMode && (
                  <p className="cpc-info">
                    {formatMoney(data?.settings?.cpc)}{' '}
                    {!costIsValid && !isEditMode && (
                      <FiAlertCircle className="budget-alert" />
                    )}
                  </p>
                )}

                {isEditMode && (
                  <InputMoney
                    id="budget.cpc"
                    name="budget.cpc"
                    required
                    loading={minCostsLoading}
                  />
                )}

                {minCostsData?.global?.min_cpc && (
                  <small className={!costIsValid && 'budget-alert'}>
                    {i18n.t('rm:minimumValue')}{' '}
                    {formatMoney(minCostsData.global.min_cpc)}
                  </small>
                )}
              </S.Wrapper>
            )}

            {!!data?.settings?.cpm && (
              <S.Wrapper>
                <S.Text>
                  {i18n.t('rm:cpm.title')}
                  <Tooltip
                    icon={<FiHelpCircle />}
                    text={i18n.t('rm:metrics.cpm.tooltip')}
                  />
                </S.Text>
                {!isEditMode && <p>{formatMoney(data?.settings?.cpm)}</p>}
                {isEditMode && (
                  <InputMoney
                    id="budget.cpm"
                    name="budget.cpm"
                    required
                    loading={minCostsLoading}
                  />
                )}

                {minCostsData?.global?.min_cpm && (
                  <small className={!costCpmIsValid && 'budget-alert'}>
                    {i18n.t('rm:minimumValue')}{' '}
                    {formatMoney(minCostsData.global.min_cpm)}
                  </small>
                )}
              </S.Wrapper>
            )}

            {!data?.settings?.daily_cost && (
              <S.Wrapper
                className={
                  isEditMode ? 'flex-grow-1 border-0 border-top px-0 pt-3' : ''
                }
              >
                {!isEditMode && (
                  <S.Text>
                    {i18n.t('rm:settings.budget_type.title')}
                    <Tooltip
                      icon={<FiHelpCircle />}
                      text={i18n.t('rm:settings.budget_type.view.description')}
                    />
                  </S.Text>
                )}

                {!isEditMode && (
                  <Badge template="success">
                    {i18n.t(
                      `rm:settings.budget_type.values.${data.settings.budget_type}.long`
                    )}
                  </Badge>
                )}
                {isEditMode && <BudgetTypeScope />}
              </S.Wrapper>
            )}
          </S.GroupWrapper>
        </S.RowWrapper>

        {isEditMode && canEdit && (
          <FormGroup className="actionButtons form-default__buttons-pb0">
            <Loading status={loadingSave}>{i18n.t('rm:savingChanges')}</Loading>

            {!loadingSave && (
              <>
                <Button
                  template="transparentDanger"
                  onClick={() => setIsEditMode(false)}
                  text={i18n.t('rm:button.Cancel')}
                  loading={loadingSave}
                />

                <Button
                  type="submit"
                  template="success"
                  loading={loadingSave}
                  disabled={loadingSave}
                  text={i18n.t('rm:button.Save')}
                  textLoading={i18n.t('rm:saving')}
                />
              </>
            )}
          </FormGroup>
        )}
      </Form>
    </Panel>
  )
}

export default Budget
